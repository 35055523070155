$(function() {
	global.getCookie = function(name) 
	{
		var start = document.cookie.indexOf(name + "=");
		var len = start + name.length + 1;
		
		if((!start) && (name != document.cookie.substring(0, name.length)))
		{
			return null;
		}
		
		if(start == -1)
		{
			return null;
		}
		
		var end = document.cookie.indexOf(";", len);
		
		if(end == -1)
		{
			end = document.cookie.length;
		}
		
		return unescape(document.cookie.substring(len, end));
	}

	global.setCookie = function(name, value, expires, path, domain, secure) 
	{
		var myCookie = name + "=" + escape(value) 
			+ ((expires) ? ";expires=" + expires.toGMTString() : "")
			+ ((path) ? ";path=" + path : "") 
			+ ((domain) ? ";domain=" + domain : "") 
			+ ((secure) ? ";secure" : "");
		document.cookie = myCookie;
	}

	global.deleteCookie = function(name, path, domain)
	{
		if(getCookie(name))
		{
			document.cookie = name + "="
				+ ((path) ? ";path=" + path : "")
				+ ((domain) ? ";domain=" + domain : "")
				+ ";expires=Thu, 01-Jan-70 00:00:01 GMT";
		}
	}
});