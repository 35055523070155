$(function () {
//	$('body').on('click', '#toggleNav', function () {
//		if ($(this).hasClass('is-active') && $('#toggleSearch').hasClass('active')) {
//			$('#toggleSearch').click();
//		};
//	});
//			
//	//Opens the mobile search menu if the desktop menu was open
//	$('body').on('click', '#toggleSearch', function () {
//		if ($(this).hasClass('active') && $('#toggleNav').hasClass('is-active')) {
//			$('#toggleNav').click();
//		};
//	});

	//Open the search input when theres an active search query
	if ($('#quickSearchInput').val()) {
		$('#quickSearchButton').trigger('click');
	}
	
	$('#quickSearchForm').submit(function(e) {
		if (!$('#quickSearchInput').val()) {
			e.preventDefault();
		}
	});
});

//$('#quickSearchButton').on('click', function () {
//	let input = $('#quickSearchInput');
//	var strLength = input.val().length * 2;
//	input.focus();
//	input[0].setSelectionRange(strLength, strLength);
//	
//	if ($('#quickSearchWrapper').hasClass('active')) {
//		$('#quickSearchForm').submit();
//	}
//});