$(function($) {
	if ($('.table').find('.sorter').length) {
		$('.table').on('click', '.sorter', function() {
			let url = $(this).data('sort-url');
			if($(this).data('refresh-element'))
			{
				let refreshElement = $('#' + $(this).data('refresh-element'));
				refreshElement.load(url);
			}
			else
			{
				window.location = url;
			}
		});
	}
	
	$('#mobile-sorter').on('change', '.sortBy', function() {
		$('#mobile-sorter .sortBy').prop('disabled', 'disabled');
		$('#mobile-sorter .sortOrder').prop('disabled', 'disabled');
		
		var url = $(this).val();
		window.location.href = url;
	});
	$('#mobile-sorter').on('change', '.sortOrder', function() {
		$('#mobile-sorter .sortBy').prop('disabled', 'disabled');
		$('#mobile-sorter .sortOrder').prop('disabled', 'disabled');
		
		var url = $(this).val();
		window.location.href = url;
	});
});