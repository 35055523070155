import swal from 'sweetalert2';
import toastr from 'toastr';
import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import moment from 'moment';

$(function($) {	

global.showConfirmSwal = function(message, resultUrl, method, title, data)
{
	swal(
	{
		title: title,
		text: message,
		type: "warning",
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		cancelButtonText: 'Nee',
		confirmButtonText: 'Ja'
	})
	.then((result) => {
		if (result.value) {
			if(method == 'get')
			{
				window.location.replace(resultUrl);
			}
			else if(method == 'post')
			{
				let csrfHeader = $('meta[name=_csrf_header]').attr('content');
				let csrfValue = $('meta[name=_csrf]').attr('content');
				$.ajax({
					url: resultUrl,
					type: method,
					headers: {
						[csrfHeader]: csrfValue
					},
					data: data || {},
					success: function(data) {
						window.location.replace(data);
					},
					error: function(jqXHR, textStatus, errorThrown) {
						toastr["error"]("Er is iets misgegaan bij het verwijderen");
					}
				});
			}
		} else {
			//do nothing
		}
	});
}

global.noUiSlider = noUiSlider;
global.wNumb = wNumb;

global.moment = moment;
moment.locale('nl');




});