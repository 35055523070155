import moment from "moment";

$(function () {
    $.fn.grafanadaterangepicker = function (overrideSettings = {}) {
        let $fromInput = $(this).siblings('input[name="from"]');
        let $toInput = $(this).siblings('input[name="to"]');
        let $pickerText = $(this).find('span');
        let from = $fromInput.val();
        let to = $toInput.val();

        if(from !== null && to !== null) {
            if(from == to) {
                $pickerText.html(getLabelByGrafanaExpression(from));
                from = getFromByLabel(getLabelByGrafanaExpression(from));
                to = getToByLabel(getLabelByGrafanaExpression(to));
            }
            else if (isNumber(from) && isNumber(to)) {
                from = moment.unix(from/1000);
                to = moment.unix(to/1000);
                $pickerText.html(from.format('YYYY, MMMM D') + ' - ' + to.format('YYYY, MMMM D'));
            }
            else {
                from = null;
                to = null;
            }
        }

        let ranges = {};

        for(const label of labels) {
            ranges[label] = [getFromByLabel(label), getToByLabel(label)];
        }

        let defaultSettings = {
            startDate: from,
            endDate: to,
            opens: "down",
            ranges: ranges,
            locale: {
                applyLabel: "Toepassen",
                cancelLabel: "Annuleren",
                fromLabel: "Van",
                toLabel: "Tot",
                customRangeLabel: "Handmatig",
                monthNames: moment.months(),
                firstDay: 1
            }
        };
        let settings = $.extend({}, defaultSettings, overrideSettings);
        this.daterangepicker(settings);


        this.on('apply.daterangepicker', function(event, picker) {
            if(picker.chosenLabel === "Handmatig") {
                $fromInput.val(picker.startDate.unix() * 1000);
                $toInput.val(picker.endDate.unix() * 1000);
                $pickerText.html(picker.startDate.format('YYYY, MMMM D') + ' - ' + picker.endDate.format('YYYY, MMMM D'));
            }
            else {
                $fromInput.val(getGrafanaExpressionByLabel(picker.chosenLabel));
                $toInput.val(getGrafanaExpressionByLabel(picker.chosenLabel));
                $pickerText.html(picker.chosenLabel);
            }

            $fromInput.change();
        });
    };

    global.refreshGrafanaPanels = function () {
        $('.grafana-panel').each(function() {
            refreshGrafanaPanel(this);
        });
    };

    global.refreshGrafanaPanel = function (panel, force=false) {
        let graphFilters = $('#graphFilter').serialize();
        let $panel = $(panel);
        $.ajax({
            'url' : $panel.data('updateuri'),
            'type' : 'POST',
            'data' : graphFilters,
            'success' : function(result) {
                if($panel.attr("src") !== result || force) {
                    $panel.attr("src", result);
                }
            }
        });
    }

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }

    const labels = ['Vandaag', 'Gisteren', 'Deze week', 'Afgelopen week', 'Deze maand', 'Afgelopen maand', 'Dit jaar', 'Afgelopen jaar'];

    function getLabelByGrafanaExpression(grafanaExpression) {
        switch (grafanaExpression) {
            case 'now/d': return 'Vandaag';
            case 'now-1d/d': return 'Gisteren';
            case 'now/w': return 'Deze week';
            case 'now-1w/w': return 'Afgelopen week';
            case 'now/M': return 'Deze maand';
            case 'now-1M/M': return 'Afgelopen maand';
            case 'now/y': return 'Dit jaar';
            case 'now-1y/y': return 'Afgelopen jaar';
        }
    }

    function getGrafanaExpressionByLabel(label) {
        switch (label) {
            case 'Vandaag': return 'now/d';
            case 'Gisteren': return 'now-1d/d';
            case 'Deze week': return 'now/w';
            case 'Afgelopen week': return 'now-1w/w';
            case 'Deze maand': return 'now/M';
            case 'Afgelopen maand': return 'now-1M/M';
            case 'Dit jaar': return 'now/y';
            case 'Afgelopen jaar': return 'now-1y/y';
        }
    }

    function getFromByLabel(label) {
        switch (label) {
            case 'Vandaag': return moment().startOf('day');
            case 'Gisteren': return moment().subtract(1, 'days').startOf('day');
            case 'Deze week': return moment().startOf('week');
            case 'Afgelopen week': return moment().subtract(1, 'weeks').startOf('week');
            case 'Deze maand': return moment().startOf('month');
            case 'Afgelopen maand': return moment().subtract(1, 'months').startOf('month');
            case 'Dit jaar': return moment().startOf('year');
            case 'Afgelopen jaar': return moment().subtract(1, 'years').startOf('year');
        }
    }

    function getToByLabel(label) {
        switch (label) {
            case 'Vandaag': return moment().endOf('day');
            case 'Gisteren': return moment().subtract(1, 'days').endOf('day');
            case 'Deze week': return moment().endOf('week');
            case 'Afgelopen week': return moment().subtract(1, 'weeks').endOf('week');
            case 'Deze maand': return moment().endOf('month');
            case 'Afgelopen maand': return moment().subtract(1, 'months').endOf('month');
            case 'Dit jaar': return moment().endOf('year');
            case 'Afgelopen jaar': return moment().subtract(1, 'years').endOf('year');
        }
    }
});