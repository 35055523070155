$(function($) {
	let basePopoverTemplate = ''
		+ '<div class="popover" role="tooltip">'
			+ '<div class="arrow"></div>'
			+ '<div class="popover-title">'
				+ '<h3 class="popover-header">'
				+ '</h3>'
			+ '</div>'
			+ '<div class="popover-body"></div>'
		+ '</div>';
	
	let closeButton = ''
		+ '<a class="close" id="close">'
			+ '<span>&times;</span>'
		+ '</a>';
	
	$(document.body).on('click', '[data-toggle="popover"]', function()
	{
		$(this).popover({
			template: basePopoverTemplate,
			title: function() {
				let t = $(this).data('title') + closeButton;
				return t;
			},
			content: function() {
				let t = $(this).data('target');
				return $(t).html();
			}
		}).popover('show');
	});
	
	$(document.body).on('click', '.popover .close' , function()
	{
        $(this).parents('.popover').popover('dispose');
    });
	
	$(document.body).on('click', '[data-vcard]', function(e) 
	{
		var cacheContainerId = 'cacheContainer__';
		function createPopover(element, cacheId, cacheKey)
		{
			return (function(responseText, statusText, xhr)
			{
				var $cacheContainer = $('#' + cacheContainerId);
				if($cacheContainer.length === 0)
				{
					$cacheContainer = $('<div id="' + cacheContainerId + '" style="display:none;"></div>');
					$cacheContainer.appendTo(document.body);
				}
				
				var $cache = $('<div class="' + cacheId + '"></div>');
				$cache.appendTo($cacheContainer);
				
				var content = responseText;
				var $content = $(content);
				$cache.append($content);
				
				//var title = '';
				attachPopover(element, cacheKey);
			});
		}
		
		function attachPopover(element, cacheKey)
		{
			var content = $(cacheKey).html();
			if(!content)
				return;
			
			$(element).popover({
				html: true, 
				content: content,
				title: function() {
					let t = $(this).data('title') + closeButton;
					return t;
				},
				placement: 'bottom',
				template: basePopoverTemplate, 
				container: 'body'
			})
			.on('shown', function()
			{
				// put this new popover on top of every other popover and modal.
				$(this).data('popover').tip().moveToFront('.popover, .modal');
			})
			.popover('show');	
		}
		
		function loadCache(url, element, cacheId, cacheKey)
		{			
			$.ajax({
				url: url,
				type: 'GET', 
				dataType: 'html', 
				cache: false,
				success: createPopover(element, cacheId, cacheKey)
			});
		}
		
		e.preventDefault();
		var vcardId = $(this).data('vcard');
		
		var cacheId = 'cachevcard' + vcardId;
		var cacheKey = '#' + cacheContainerId + ' > .' + cacheId;
		
		var $cache = $(cacheKey);
		if($cache.length === 0)
		{
			var hasPopover = $(this).data('popover');		
			if(!hasPopover)
			{
				var url = this.href;
				loadCache(url, this, cacheId, cacheKey);
			}
		}
		else
		{
			attachPopover(this, cacheKey);
		}
	});
});