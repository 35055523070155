$(function($) {
	var lastClicked = false;
	$('.select-table').each(function (index,value) {
		let $table = $(this);
		$table.on('change', '.select-table-checkall', function(e) {
			$('.select-table-checkbox').each(function() {
				this.checked = e.target.checked;
			});
			lastClicked = false;
		});
		
		$table.on('click', '.select-table-checkbox + .custom-control-label', function(e) {
			//Shift key range selection
			e.preventDefault();
			let $checkbox = $(this).prev();
			$checkbox.prop('checked', $checkbox.is(':checked') ? '' : 'checked');
			
			if(e.shiftKey) {
				if(lastClicked) {
					var checks = $table.find(':checkbox.select-table-checkbox');
					var first = checks.index(lastClicked);
					var last = checks.index($checkbox);
					if(first > last)
					{
						// select bottom to upper
						var tmp = first;
						first = last;
						last = tmp;	
					}
					if(0 <= first && 0 <= last && first != last) 
					{
						checks.slice(first, last).prop('checked', $checkbox.is(':checked') ? 'checked' : '');
					}
				}
			}
			
			if($table.find('.select-table-checkbox').length == $table.find('.select-table-checkbox:checked').length)
			{
				$table.find('.select-table-checkall').prop('checked', true);
			}
			else
			{
				$table.find('.select-table-checkall').prop('checked', false);
			}
			lastClicked = $checkbox;
		});
	});
});