// Imports

import $ from "jquery";
import 'bootstrap';
import 'metismenu';
import 'bootstrap-select';
import PerfectScrollbar from 'perfect-scrollbar';
import Chart from 'chart.js';
import toastr from 'toastr';
import Cookies from "js-cookie";
import resizable from "webpack-jquery-ui/resizable";
import "webpack-jquery-ui/sortable";
import "jquery-ui-touch-punch";
import NProgress from 'multi-nprogress';
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode";
import mapboxgl from 'mapbox-gl'

// Themes
import './assets/classic.scss';
import './assets/dark.scss';
import './assets/default.scss';


global.$ = $;
global.jQuery = $;
global.Chart = Chart;
global.toastr = toastr;
global.Cookies = Cookies;
global.resizable = resizable;
global.Html5Qrcode = Html5Qrcode;
global.Html5QrcodeSupportedFormats = Html5QrcodeSupportedFormats;
global.mapboxgl = mapboxgl;
//global.jTest = $;

const nprogress = new NProgress();

(function () {
    nprogress.configure({
        showSpinner: false
        //, parent: '.app-header'
    });

    let activeRequests = 0;

    function nStart() {
        if (activeRequests === 0) {
            nprogress.start();
        }
        activeRequests++;
    }

    function nDone() {
        activeRequests--;
        if (activeRequests === 0) {
            nprogress.done();
        }
    }

    $(document).ajaxStart(nStart);
    $(document).ajaxStop(nDone);

    $(window).on('beforeunload', nStart);

    $.ajaxSetup({
        statusCode: {
            // 901: Ajax session/authentication error detected, reloading window required to trigger login/2fa.
            901: function () {
                //console.log('Session timeout detected');
                window.location.reload();
            }
        }
    });
})();

$(document).ready(() => {

    //PerfectNotificationScrollbar Initializer
    window.PerfectNotificationScrollbarHandler = function (container) {
        const ps = new PerfectScrollbar(container, {
            wheelSpeed: 2,
            wheelPropagation: false,
            minScrollbarLength: 20
        });
    }

    // Sidebar Menu

//    setTimeout(function () {
    $(".vertical-nav-menu").metisMenu();
//    }, 100);

    // Search wrapper trigger

    $('.search-icon').click(function () {
        let $grandparent = $(this).parent().parent();

        if ($grandparent.hasClass('active')) {
            $('#quickSearchForm').submit();
        } else {
            $grandparent.addClass('active');
            $grandparent.find('#quickSearchInput').focus();
        }
    });

//    $('.search-wrapper .close').click(function () {
//        $(this).parent().removeClass('active');
//    });


    // BS4 Popover

//    $('[data-toggle="popover-custom-content"]').each(function (i, obj) {
//
//        $(this).popover({
//            html: true,
//            placement: 'auto',
//            template: '<div class="popover popover-custom" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
//            content: function () {
//                var id = $(this).attr('popover-id');
//                return $('#popover-content-' + id).html();
//            }
//        });
//
//    });

    // Stop Bootstrap 4 Dropdown for closing on click inside

    $('.dropdown-menu').on('click', function (event) {
        if (!$(this).hasClass("close-on-click")) //Fix for opening drawer from dropdown
        {
            var events = $._data(document, 'events') || {};
            events = events.click || [];
            for (var i = 0; i < events.length; i++) {
                if (events[i].selector) {
                    if ($(event.target).is(events[i].selector)) {
                        events[i].handler.call(event.target, event);
                    }
                    $(event.target).parents(events[i].selector).each(function () {
                        events[i].handler.call(this, event);
                    });
                }
            }
            event.stopPropagation(); //Always stop propagation
        }
    });


    $('body').on('click', function (e) {
        $('[rel="popover-focus"]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });


    // BS4 Tooltips

    function activeTooltip(e) {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="tooltip-light"]').tooltip({
            template: '<div class="tooltip tooltip-light"><div class="tooltip-inner"></div></div>'
        });
    }

    $(activeTooltip);
    $(document).ajaxComplete(activeTooltip);

    // Drawer

    $('.open-right-drawer').click(function () {
        $(this).addClass('is-active');
        $('.app-drawer-wrapper').addClass('drawer-open');
        $('.app-drawer-overlay').removeClass('d-none');
    });

    $('.drawer-nav-btn').click(function () {
        $('.app-drawer-wrapper').removeClass('drawer-open');
        $('.app-drawer-overlay').addClass('d-none');
        $('.open-right-drawer').removeClass('is-active');
    });

    $('.app-drawer-overlay').click(function () {
        $(this).addClass('d-none');
        $('.app-drawer-wrapper').removeClass('drawer-open');
        $('.open-right-drawer').removeClass('is-active');
    });

    $('.mobile-toggle-nav').click(function () {
        $(this).toggleClass('is-active');
        $('.app-container').toggleClass('sidebar-mobile-open');
    });

    $('.mobile-toggle-header-nav').click(function () {
        $(this).toggleClass('active');
        $('.app-header__content').toggleClass('header-mobile-open');
    });

    $('.mobile-app-menu-btn').click(function () {
        $('.hamburger', this).toggleClass('is-active');
        $('.app-inner-layout').toggleClass('open-mobile-menu');
    });

    $(document).on('click', '[data-hiding]', function (e) {
        let $this = $(e.target);
        let target = $this.data('hiding');
        $(target).slideToggle().toggleClass('hide');
        if (!$this.data('keepSelf')) {
            $this.toggleClass('hide', !$(target).hasClass('hide'));
        }
    })


    // Find all collapsebles and register a click event. When clicked, check if there is a
    // flexrefresher parent and pause it. This will prevent the refresh (and re-collapse) of
    // an expanded collapse (where the user is searching for an specific item).
    function collapsePauseRefresher() {
        let $collapse = $(this);
        let $flexrefresher = $collapse.parents('[data-flex="flexrefresher"]');
        $flexrefresher.flexRefresher('pause');
    }

    $(document).on('click', '[data-toggle="collapse"]', function () {
        let $collapse = $($(this).data('target'));
        $collapse.on('show.bs.collapse', collapsePauseRefresher);
    });
});

