import toastr from 'toastr';
import 'block-ui';

$(function($) {
	toastr.options = {
		"closeButton": true,
		"debug": false,
		"newestOnTop": true,
		"progressBar": true,
		"positionClass": "toast-bottom-center",
		"preventDuplicates": false,
		"onclick": null,
		"showDuration": "300",
		"hideDuration": "1000",
		"timeOut": "5000",
		"extendedTimeOut": "1000",
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};

	$.blockUI.defaults = {
		css: {
			top: 0,
			border: 'unset',
			backgroundColor: 'unset'
		}
	};
});

global.openModal = function(baseUrl, modalUri, modalId)
{
	$.ajax({
		type: 'GET',
		url : baseUrl + modalUri,
		success : function(data) {
			$('#modalWrapper').html(data);
			$('#' + modalId).modal();
			$('#' + modalId).modal('show');
		}
	});
}

function closeFormModal(modal) {
	var modal = $(modal);
	$(modal).modal('hide');
	$('.modal-backdrop').remove();
}

var redirect = function (contentId) {
	return function (data, textStatus, jqXHR) {
		var uri = jqXHR.getResponseHeader("Location");
		window.location.replace(uri);
	};
};

var displayModal = function(contentId)
{
	return function(data, textStatus, jqXHR)
	{
		if (jqXHR && jqXHR.status == 201) {
			return; // handled by statusCode:201 fn.
		}

		if (data) {
			$('#modalWrapper').html(data);
			let modal = $('#' + contentId + 'Modal');
			closeFormModal(modal);

			$(modal).modal('show');
		} else {
			// ???
		}
	};
};

var displayError = function(contentId)
{
	return function(jqXHR, textStatus, errorThrown)
	{
		unblockAsyncForm(contentId);
		toastr["error"]('Kan geen verbinding maken met de server');
	};
};


function blockAsyncForm(contentId) {
	$('#' + contentId).block({
		message:
			'<div class="loader mx-auto">' +
			'<div class="ball-pulse">' +
			'<div class="bg-white"></div>' +
			'<div class="bg-white"></div>' +
			'<div class="bg-white"></div>' +
			'</div>' +
			'</div>'
	});
}

function unblockAsyncForm(contentId)
{
	$('#' + contentId).unblock();
}

global.installAsyncModalForm = function(contentId, options)
{
	options = options || {};

	var modalId = '#' + contentId + 'Modal';
	var formId = '#' + contentId + 'Form';

	var asyncFormHandler2 = function(event)
	{
		if(typeof options.submit == 'function') {
			var ret = options.submit.apply(this, arguments);

			if (ret === false)
				return false;

			if (event.isDefaultPrevented())
				return;

			delete options.submit;
		}

		let $modal = $(modalId);
		let $form = $modal.find(formId);

		let formData = $form.serializeArray();

		if (event.target.name && event.target.value) {
			formData.push({name: event.target.name, value: event.target.value});
		}

		event.preventDefault();

		blockAsyncForm(contentId);

		var ajaxOptions = $.extend({
			type: 'POST',
			url: $form.attr('action'),
			data: $.param(formData),
			headers: {'X-No-Redirect': true},
			success: displayModal(contentId),
			statusCode: {
				//"201 Created"  "202 Accepted"  "204 No Content"
				201: redirect(contentId)
			},
			error: displayError(contentId)
		}, options);

		$.ajax(ajaxOptions);
	};

	jQuery(function ($) {
		var $form = $(modalId).find(formId);
		$form.on('submit', asyncFormHandler2);

		$(modalId).on('hide.bs.modal', function (e) {
			$('#modalWrapper').empty();
		});
	});
};