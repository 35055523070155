global.installExclusiveCheckHandler = function(elements)
{
	if(elements.length <= 1)
		return;

	function exclusiveChecker(event)
	{
		elements.not([this]).prop('checked', false);
	}

	elements.unbind('change', exclusiveChecker).change(exclusiveChecker);
}		
