import Chart from 'chart.js';

Chart.defaults.gauge = Chart.defaults.doughnut;

var gauge = Chart.controllers.doughnut.extend({
	draw: function(ease) {
		// Call super method first
		Chart.controllers.doughnut.prototype.draw.call(this, ease);

		var ctx = this.chart.chart.ctx;
		var chart = this.chart.chart;
		
		var helperValues = this.chart.config.data.helperValues;
		
		var originFactor = 0.05;
		var pointerFactor = 0.75;
		var xOrigin = (chart.width / 2);
		var yOrigin = chart.height - (chart.width * originFactor);
		var angleInDegrees = ((Math.min(helperValues[0], helperValues[2]) / helperValues[2]) * 194) + 173;
		
		var originRadius = (chart.width * 0.03);
		ctx.beginPath();
		ctx.arc(xOrigin, yOrigin, originRadius, 0, 2 * Math.PI);
		ctx.fillStyle = "#4c4c4c";
		ctx.fill();
		ctx.closePath();

		var x = ((originRadius * pointerFactor) * Math.cos((angleInDegrees + 90)* Math.PI / 180)) + xOrigin;
		var y = ((originRadius * pointerFactor) * Math.sin((angleInDegrees + 90) * Math.PI / 180)) + chart.height;
		var startX = x;
		var startY = y;
		
		ctx.beginPath();
		ctx.moveTo(x, y - (chart.width * originFactor));
		
		x = ((originRadius * pointerFactor) * Math.cos((angleInDegrees - 90) * Math.PI / 180)) + xOrigin;
		y = ((originRadius * pointerFactor) * Math.sin((angleInDegrees - 90) * Math.PI / 180)) + chart.height;
		ctx.lineTo(x, y - (chart.width * originFactor));

		var chartRadius = chart.innerRadius;
		x = (chartRadius * Math.cos(angleInDegrees * Math.PI / 180)) + xOrigin;
		y = (chartRadius * Math.sin(angleInDegrees  * Math.PI / 180)) + chart.height;
		ctx.lineTo(x, y - (chart.width * originFactor));
		ctx.lineTo(startX, startY - (chart.width * originFactor));
		ctx.fillStyle = "#4c4c4c";
		ctx.strokeStyle = "#4c4c4c"
		ctx.stroke();
		ctx.fill();
		ctx.closePath();

		ctx.beginPath();
		ctx.textBaseline = "bottom";
		ctx.font = (chart.width * 0.03) + "px Arial";
		ctx.textAlign = "start";
		ctx.fillText(helperValues[1], (xOrigin - chart.innerRadius + 5), chart.height - 2);
		ctx.textAlign = "end";
		ctx.fillText(helperValues[2], (xOrigin + chart.innerRadius - 5), chart.height - 2);
		ctx.closePath();
	}
});

Chart.controllers.gauge = gauge;