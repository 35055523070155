$(function($) {
	if ($('.app-main').find('.sticky-title').length) {
		$(window).on('scroll', function() {
			var scrollTop = $(window).scrollTop();
			if (scrollTop >= 60) {
				$('.app-main').find('.app-page-title.sticky-title').stop().animate({
					'padding-bottom': "10px",
					'padding-top': '10px'
				}, 0);
			} else if (scrollTop == 0) {
				$('.app-main').find('.app-page-title.sticky-title').stop().animate({
					'padding-bottom': "30px",
					'padding-top': '30px'
				}, 0);
			}
		});
	}
});