import 'daterangepicker';
import moment from 'moment';

$.fn.singleDatePicker = function(options) {
	let optional = options && !!options.optional;
	let dateFormat = options && options.dateFormat;
	return this.each(function() {
		if (optional) {
			// autoUpdateInput required manual apply callback
			$(this).on("apply.daterangepicker", function(e, picker) {
				picker.element.val(picker.startDate.format(picker.locale.format));
			});
		}
		options = $.extend({}, options, {
			singleDatePicker: true,
			autoUpdateInput: !optional,
			locale: {
				format: dateFormat,
				"applyLabel": i18n.getMessage("daterangepicker.label.apply"),
				"cancelLabel": i18n.getMessage("daterangepicker.label.cancel"),
				"daysOfWeek": [
					i18n.getMessage("daterangepicker.day.Sunday.short"),
					i18n.getMessage("daterangepicker.day.Monday.short"),
					i18n.getMessage("daterangepicker.day.Tuesday.short"),
					i18n.getMessage("daterangepicker.day.Wednesday.short"),
					i18n.getMessage("daterangepicker.day.Thursday.short"),
					i18n.getMessage("daterangepicker.day.Friday.short"),
					i18n.getMessage("daterangepicker.day.Saturday.short")
				],
				"monthNames": [
					i18n.getMessage("daterangepicker.month.January"),
					i18n.getMessage("daterangepicker.month.February"),
					i18n.getMessage("daterangepicker.month.March"),
					i18n.getMessage("daterangepicker.month.April"),
					i18n.getMessage("daterangepicker.month.May"),
					i18n.getMessage("daterangepicker.month.June"),
					i18n.getMessage("daterangepicker.month.July"),
					i18n.getMessage("daterangepicker.month.August"),
					i18n.getMessage("daterangepicker.month.September"),
					i18n.getMessage("daterangepicker.month.October"),
					i18n.getMessage("daterangepicker.month.November"),
					i18n.getMessage("daterangepicker.month.December")
				],
				"firstDay": 1
			}
		});

		if (options.minDate == null) {
			options.minDate = false;
		}
		
		if (options.maxDate == null) {
			options.maxDate = false;
		}
		
		$(this).daterangepicker(options);
	});
};

$.fn.dateRangePicker = function(options) {
	let dateFormat = options && options.dateFormat;
	
	var ranges = {};
	
	ranges[i18n.getMessage("daterangepicker.todayOrYesterday")] = [moment().subtract(1, 'days'), moment()];
	ranges[i18n.getMessage("daterangepicker.last7Days")] = [moment().subtract(6, 'days'), moment()];
	ranges[i18n.getMessage("daterangepicker.last30Days")] = [moment().subtract(29, 'days'), moment()];
	
	return this.each(function() {
		options = $.extend({}, options, {
			locale: {
				format: dateFormat,
				"applyLabel": i18n.getMessage("daterangepicker.label.apply"),
				"cancelLabel": i18n.getMessage("daterangepicker.label.cancel"),
				fromLabel: i18n.getMessage("daterangepicker.label.from"),
				toLabel: i18n.getMessage("daterangepicker.label.to"),
				weekLabel: i18n.getMessage("daterangepicker.label.week"),
				customRangeLabel: i18n.getMessage("daterangepicker.label.customRange"),
				"daysOfWeek": [
					i18n.getMessage("daterangepicker.day.Sunday.short"),
					i18n.getMessage("daterangepicker.day.Monday.short"),
					i18n.getMessage("daterangepicker.day.Tuesday.short"),
					i18n.getMessage("daterangepicker.day.Wednesday.short"),
					i18n.getMessage("daterangepicker.day.Thursday.short"),
					i18n.getMessage("daterangepicker.day.Friday.short"),
					i18n.getMessage("daterangepicker.day.Saturday.short")
				],
				"monthNames": [
					i18n.getMessage("daterangepicker.month.January"),
					i18n.getMessage("daterangepicker.month.February"),
					i18n.getMessage("daterangepicker.month.March"),
					i18n.getMessage("daterangepicker.month.April"),
					i18n.getMessage("daterangepicker.month.May"),
					i18n.getMessage("daterangepicker.month.June"),
					i18n.getMessage("daterangepicker.month.July"),
					i18n.getMessage("daterangepicker.month.August"),
					i18n.getMessage("daterangepicker.month.September"),
					i18n.getMessage("daterangepicker.month.October"),
					i18n.getMessage("daterangepicker.month.November"),
					i18n.getMessage("daterangepicker.month.December")
				],
				"firstDay": 1
			},
			ranges: ranges
		},);
		$(this).daterangepicker(options, 
			function(startDate, endDate) 
			{
				$('#dateRange span').html(startDate.format('DD-MM-YYYY') + ' - ' + endDate.format('DD-MM-YYYY'));
				$('#minDate').val(startDate.format('DD-MM-YYYY HH:mm'));
				$('#maxDate').val(endDate.format('DD-MM-YYYY HH:mm'));
			}
		);
	});
};